﻿ export const page_text_es={
	
	// Servicios
	sati_name:"SATI",
	sati_desc:"Servicio de acompañamiento en terapia inhalada",
	satib_name:"SATIB",
	satib_desc:"Servicio básico de acompañamiento en terapia inhalada",
	iretia_name:"IRETIA",
	iretia_desc:"Intervención rápida en terapia inhalada aguda",
	cepoc_name:"CEPOC",
	cepoc_desc:"Cribado de Enfermedad Pulmonar Obstructiva Crónica EPOC",
	rcm_name:"RCM",
	rcm_desc:"Control de riesgo cardio metabólico",
	adir_name:"ADIR",
	adir_desc:"Ajuste de dosis en insuficiencia renal",

	// Navigation
	nav_home:"Inicio",
	nav_patients:"Pacientes",
	nav_services:"Servicios",
	nav_profile:"Perfil",
	nav_calendar:"Agenda",
	nav_reports:"Consultas",
	nav_videos:"Formación",
	nav_language:"Idioma",
	nav_language_ca:"Català",
	nav_language_es:"Español",
	nav_logout:"Cerrar sesión",
	nav_owner:"Modo titular",

	
	// Login
	login_title:"Inicio de sesión",
	login_user_placeholder:"Usuario o email",
	login_password_placeholder:"Contraseña",
	login_button:"Autentificar",
	login_error_user:"Usuario o contraseña no válidos.",
	login_error_server:"Error de conexión.",

	login_password_forgotten:"Si ha olvidado la contraseña, introduzca el email y pulse [Recuperar contraseña] para recibir un correo con un código de recuperación.",
	login_send_password:"Recuperar contraseña",
	login_error_recover:"Error al inciar proceso de recuperación.",
	login_error_email:"Correo de recuperación no válido.",
	login_recover_ok:"Código enviado al correo.",

	update_password_title:"Actualizar contraseña",
	update_password_info:"Por motivos de seguridad, tiene que crear una contraseña nueva para acceder al aplicativo.",
	update_password_placeholder:"Contraseña nueva",
	update_repassword_placeholder:"Confirmar la contraseña nueva",
	update_password_button:"Actualizar contraseña",
	update_password_must_1:"Debe contener al menos un número.",
	update_password_must_2:"Debe contener al menos una letra mayúsucla.",
	update_password_must_3:"Debe contener al menos un símbolo.",
	update_password_must_4:"Debes confirmar contraseña.",
	update_error:"Problema al actualizar contraseña. Por favor, vuelva a intentarlo antes de contactar con Farmaval.",

	alert_code_title:"Modo Titular",
	alert_code_info:"Para acceder al modo titular debe introducir la contraseña:",
	alert_code_wrong:"Contraseña de titular incorrecta.",
	alert_code_recover:"He olvidado la contraseña",
	alert_code_recover_info:"Se enviará un email a la dirección de correo del titular.",
	alert_code_recover_email:"Se ha enviado un email con la nueva contraseña a la dirección: ",
	alert_code_recover_error:"Se ha producido un error al enviar el correo con la nueva contraseña.",
	
	// Formación
	formation_sati:"Servicio SATI",
	formation_iretia:"Servicio IRETIA",
	formation_cepoc:"Servicio CEPOC",
	formation_all:"Sesiones de valor",
	
	videos_label:"Videos",
	all_videos_label:"Para el responsable",
	team_videos_label:"Para el equipo",
	material_label:"Material adicional",
	video_iretia_1:"1. Taller inhaladores Presurizados.",
	video_iretia_2:"2. Taller inhaladores Camara de inhalación.",
	video_iretia_3:"3. Taller inhaladores polvo seco monodosis.",
	video_iretia_4:"4. Taller inhaladores polvo seco multidosis con depósito.",
	video_iretia_5:"5. Taller de inhaladores polvo seco con blíster.",
	video_iretia_6:"6. Conceptos de aerosolterapia y distribución de partículas.",
	video_iretia_7:"7. La técnica inhalada.",
	link_iretia_1:"https://player.vimeo.com/video/838398122",
	link_iretia_2:"https://player.vimeo.com/video/688355173",
	link_iretia_3:"https://player.vimeo.com/video/684574347",
	link_iretia_4:"https://player.vimeo.com/video/688361792",
	link_iretia_5:"https://player.vimeo.com/video/688367203",
	link_iretia_6:"https://player.vimeo.com/video/688371703",
	link_iretia_7:"https://player.vimeo.com/video/688623093",
	material_iretia_1:"1- Consenso asma pediatría 2021 REGAP.",
	material_iretia_2:"2- Dossier formativo.",
	material_iretia_3:"3- Informe dispositivos de polvo seco unidosis.",
	material_iretia_4:"4- Informe dispositivos de polvo seco multidosis.",
	material_iretia_5:"5-Informe dispositivos presurizados sin cámara.",
	material_iretia_6:"6- Informe dispositivos presurizados con cámara.",
	material_iretia_7:"7- Informe dispositivos presurizados con cámara en niños y lactantes.",
	file_iretia_1:"https://www.farmaval.net/docs/8-%20Consenso%20asma%20pediatria%202021%20REGAP.pdf",
	file_iretia_2:"https://www.farmaval.net/docs/9-Dossier%20formativo.pdf",
	file_iretia_3:"https://www.farmaval.net/docs/iretia/es/polvo_seco_unidosis.pdf",
	file_iretia_4:"https://www.farmaval.net/docs/iretia/es/polvo_seco_multidosis.pdf",
	file_iretia_5:"https://www.farmaval.net/docs/iretia/es/presurizados_sin_c%C3%A1mara.pdf",
	file_iretia_6:"https://www.farmaval.net/docs/iretia/es/presurizados_con_c%C3%A1mara.pdf",
	file_iretia_7:"https://www.farmaval.net/docs/iretia/es/presurizados_c%C3%A1mara_lactantes.pdf",

	video_sati_1:"1. Una historia y un sueño.",
	video_sati_2:"2. ¿Por qué es necesario el servicio SATI?",
	video_sati_3:"3. Nuestro rol como profesionales sanitarios.",
	video_sati_4:"4. Taller inhaladores Presurizados.",
	video_sati_5:"5. Taller inhaladores Camara de inhalación.",
	video_sati_6:"6. Taller inhaladores polvo seco monodosis.",
	video_sati_7:"7. Taller inhaladores polvo seco multidosis con depósito.",
	video_sati_8:"8. Taller de inhaladores polvo seco con blíster.",
	video_sati_9:"9. Taller de inhaladores: Material de soporte.",
	video_sati_10:"10. Conclusiones y plan de acción para todo el equipo.",
	video_sati_11:"11. Conceptos de aerosolterapia y distribución de partículas.",
	video_sati_12:"12. La técnica inhalada.",
	video_sati_13:"13. El aparato respiratorio.",
	video_sati_14:"14. Patologia respiratoria y Principales diferencias entre Asma y EPOC.",
	video_sati_15:"15. El servicio SATI definición.",
	video_sati_16:"16. Funcionamiento de Farmaval App.",
	video_sati_17:"17. Recogida de datos generales del paciente.",
	video_sati_18:"18. SATI LOPD.",
	video_sati_19:"19. Diagnostico y farmacoterapia.",
	video_sati_20:"20. Cuestionarios de control de la patología respiratoria.",
	video_sati_21:"21. Educación sanitaria sobre la patología respiratoria.",
	video_sati_22:"22. Valoración de la técnica inhalada.",
	video_sati_23:"23. Formación y mejora de la técnica inhalada.",
	video_sati_24:"24. Los dispositivos de entrenamiento.",
	video_sati_25:"25. Control del flujo inspiratorio.",
	video_sati_26:"26. La prescripción farmacéutica y el talonario de prescripción.",
	video_sati_27:"27. Prescripción de la cámara A2A y mascarilla de inhalación.",
	video_sati_28:"28. Prescripción de InmunoRespir.",
	video_sati_29:"29. Prescripción para las mucosas orales afectadas.",
	video_sati_30:"30. Encuesta final al paciente.",
	video_sati_31:"31. Generación de informes y próxima cita.",
	video_sati_32:"32. Informe de paciente.",
	video_sati_33:"33. Informe de derivación por flujo insuficiente.",
	video_sati_34:"34. Otros informes de derivación.",
	video_sati_35:"35. Ofrecimiento del servicio en el mostrador.",
	video_sati_36:"36. Programar nuevas citas en la agenda.",
	video_sati_37:"37. Registro de las prescripciones aceptadas.",
	video_sati_38:"38. Sistema de recordatorios y mensajería.",
	video_sati_39:"39. Medidas Covid.",
	video_sati_40:"40. ¿Quién hay detrás de Farmaval?",
	video_sati_41:"41. Elección inhalador principal.",
	video_sati_42:"42. ¿Que ocurre si paciente usa dos inhaladores diferentes?",
	video_sati_43:"43. Paciente EPOC e inhalador monodosis polvo seco Handihaler.",
	video_sati_44:"44. Paciente EPOC con enfisema pulmonar e inhalador de niebla fina Respimat.",
	video_sati_45:"45. Paciente Asmático e inhalador de polvo seco Turbuhaler.",

	link_sati_1:"https://player.vimeo.com/video/684573034",
	link_sati_2:"https://player.vimeo.com/video/685392520",
	link_sati_3:"https://player.vimeo.com/video/688345206",
	link_sati_4:"https://player.vimeo.com/video/838398122",
	link_sati_5:"https://player.vimeo.com/video/688355173",
	link_sati_6:"https://player.vimeo.com/video/684574347",
	link_sati_7:"https://player.vimeo.com/video/688361792",
	link_sati_8:"https://player.vimeo.com/video/688367203",
	link_sati_9:"https://player.vimeo.com/video/688368760",
	link_sati_10:"https://player.vimeo.com/video/838405767",
	link_sati_11:"https://player.vimeo.com/video/688371703",
	link_sati_12:"https://player.vimeo.com/video/688623093",
	link_sati_13:"https://player.vimeo.com/video/688610397",
	link_sati_14:"https://player.vimeo.com/video/688626444",
	link_sati_15:"https://player.vimeo.com/video/688610899",
	link_sati_16:"https://player.vimeo.com/video/701189206",
	link_sati_17:"https://player.vimeo.com/video/684575488",
	link_sati_18:"https://player.vimeo.com/video/684576687",
	link_sati_19:"https://player.vimeo.com/video/685396491",
	link_sati_20:"https://player.vimeo.com/video/685397299",
	link_sati_21:"https://player.vimeo.com/video/685399122",
	link_sati_22:"https://player.vimeo.com/video/685399333",
	link_sati_23:"https://player.vimeo.com/video/688612675",
	link_sati_24:"https://player.vimeo.com/video/688459718",
	link_sati_25:"https://player.vimeo.com/video/688462696",
	link_sati_26:"https://player.vimeo.com/video/701189730",
	link_sati_27:"https://player.vimeo.com/video/688614103",
	link_sati_28:"https://player.vimeo.com/video/688629258",
	link_sati_29:"https://player.vimeo.com/video/688616792",
	link_sati_30:"https://player.vimeo.com/video/688617394",
	link_sati_31:"https://player.vimeo.com/video/688620317",
	link_sati_32:"https://player.vimeo.com/video/688617623",
	link_sati_33:"https://player.vimeo.com/video/688621404",
	link_sati_34:"https://player.vimeo.com/video/701190158",
	link_sati_35:"https://player.vimeo.com/video/688621835",
	link_sati_36:"https://player.vimeo.com/video/688618095",
	link_sati_37:"https://player.vimeo.com/video/688465095",
	link_sati_38:"https://player.vimeo.com/video/688466020",
	link_sati_39:"https://player.vimeo.com/video/685406481",
	link_sati_40:"https://player.vimeo.com/video/685413171",
	link_sati_41:"https://player.vimeo.com/video/838385660",
	link_sati_42:"https://player.vimeo.com/video/838386868",
	link_sati_43:"https://player.vimeo.com/video/891112561",
	link_sati_44:"https://player.vimeo.com/video/891123647",
	link_sati_45:"https://player.vimeo.com/video/891147645",

	material_sati_1:"1- Estudio EPOCONSUL.",
	material_sati_2:"2- Informe de resultados ASPIRA 2014.",
	material_sati_3:"3- Revisión sistemática 2016 sobre errores en terapia inhalada.",
	material_sati_4:"4- Programa SABINA sobreuso SABA.",
	material_sati_5:"5- MiniGEMA5.1.",
	material_sati_6:"6- GINA Pocket Guide 2021.",
	material_sati_7:"7- GESEPOC2021.",
	material_sati_8:"8- Consenso asma pediatría 2021 REGAP.",
	material_sati_9:"9- Dossier formativo.",
	material_sati_10:"10- Algoritmo de resistencias de In-Check Dial.",
	material_sati_11:"11- Tabla de flujos mínimos y óptimos por dispositivo.",
	material_sati_12:"12- Pauta InmunoRespir.",
	material_sati_13:"13- Ofrecimiento del servicio SATI.",
	material_sati_14:"14- Cuestionario para descartar Covid-19.",
	material_sati_15:"15- Proveedores de productos SATI para farmacias.",
	material_sati_16:"16- Modelo solicitud ejercicio derechos del interesado.",
	material_sati_17:"17- Consentimiento cesión imágenes y vídeos pacientes SATI.",
	material_sati_18:"18- Instrucciones solicitudes del interesado en la Farmacia.",
	material_sati_19:"19- Informe del paciente.",
	material_sati_20:"20- Informe de derivación básico.",
	material_sati_21:"21- Informe de derivación por flujo.",
	material_sati_22:"22- Infografia asma y colageno.",
	material_sati_23:"23- Tabla de flujos óptimos por dispositivo.",
	material_sati_24:"24- Elección inhalador principal. Castellano",
	material_sati_25:"25- Pauta InmunoRespir.",
	material_sati_26:"26- Quecetina.",
	material_sati_27:"27- Mapa mental 3 acciones inhaladores.",

	file_sati_1:"https://www.farmaval.net/docs/1-Estudio%20EPOCONSUL.pdf",
	file_sati_2:"https://www.farmaval.net/docs/2-Informe%20de%20resultados%20ASPIRA%202014.pdf",
	file_sati_3:"https://www.farmaval.net/docs/3-%20Revisi%C3%B3n%20sistematica%202016%20sobre%20errores%20en%20terapia%20inhalada.pdf",
	file_sati_4:"https://www.farmaval.net/docs/4-%20Programa%20SABINA%20sobreuso%20SABA.pdf",
	file_sati_5:"https://www.farmaval.net/docs/5-%20MiniGEMA5.1.pdf",
	file_sati_6:"https://www.farmaval.net/docs/6-%20GINA%20Pocket%20Guide%202021.pdf",
	file_sati_7:"https://www.farmaval.net/docs/7-%20GESEPOC2021.pdf",
	file_sati_8:"https://www.farmaval.net/docs/8-%20Consenso%20asma%20pediatria%202021%20REGAP.pdf",
	file_sati_9:"https://www.farmaval.net/docs/9-Dossier%20formativo.pdf",
	file_sati_10:"https://www.farmaval.net/docs/10-%20Algoritmo%20de%20resistencias%20de%20In-Check%20Dial.pdf",
	file_sati_11:"https://www.farmaval.net/docs/11-%20Tabla%20de%20flujos%20m%C3%ADnimos%20y%20%C3%B3ptimos%20por%20dispositivo.pdf",
	file_sati_12:"https://www.farmaval.net/docs/12-%20Pauta%20InmunoRespir.pdf",
	file_sati_13:"https://www.farmaval.net/docs/13-%20Ofrecimiento%20del%20servicio%20SATI.pdf",
	file_sati_14:"https://www.farmaval.net/docs/14-%20Cuestionario%20para%20descartar%20Covid-19.pdf",
	file_sati_15:"https://www.farmaval.net/docs/15-%20Proveedores%20de%20productos%20SATI%20para%20farmacias.pdf",
	file_sati_16:"https://www.farmaval.net/docs/16-%20Modelo%20solicitud%20ejercicio%20derechos%20del%20interesado.pdf",
	file_sati_17:"https://www.farmaval.net/docs/17-Consentimiento%20cesi%C3%B3n%20imagenes%20y%20videos%20pacientes%20SATI%20.pdf",
	file_sati_18:"https://www.farmaval.net/docs/18-Instrucciones%20solicitudes%20del%20intereado%20en%20la%20Farmacia.pdf",
	file_sati_19:"https://www.farmaval.net/docs/19-Informe%20del%20paciente.pdf",
	file_sati_20:"https://www.farmaval.net/docs/20-%20Informe%20de%20derivaci%C3%B3n%20b%C3%A1sico.pdf",
	file_sati_21:"https://www.farmaval.net/docs/21-%20Informe%20de%20derivaci%C3%B3n%20por%20flujo.pdf",
	file_sati_22:"https://www.farmaval.net/docs/22-%20Infografia%20asma%20y%20colageno.pdf",
	file_sati_23:"https://www.farmaval.net/docs/23-%20Tabla%20de%20flujos%20%C3%B3ptimos%20por%20dispositivo.pdf",
	file_sati_24:"https://www.farmaval.net/docs/24-%20Elecci%C3%B3n%20inhalador%20principal.pdf",
	file_sati_25:"https://www.farmaval.net/docs/25-%20Pauta%20InmunoRespir.pdf",
	file_sati_26:"https://www.farmaval.net/docs/26-%20Quecetina.pdf",
	file_sati_27:"https://www.farmaval.net/docs/27-%20Mapa%20mental%203%20acciones%20inhaladores.pdf",

	// Calendar 
	calendar_dates:"Citas",
	calendar_follow:"Seguimiento",
	day_1:"Lu",
	day_2:"Ma",
	day_3:"Mi",
	day_4:"Ju",
	day_5:"Vi",
	day_6:"Sá",
	day_7:"Do",
	calendar_months:"Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre",	
	date_btn_add:"Añadir cita",	
	date_btn_update:"Actualizar",
	date_btn_delete:"Eliminar",
	date_confirm_delete:"Teclee <b>eliminar cita</b> y pulse 'Eliminar':",
	date_confirm_delete_text:"eliminar cita",

	date_no_shift:"No hay",

	date_popup_title:"Programar cita",
	date_lbl_patient:"Paciente",
	date_lbl_staff:"Encargado/a",
	date_lbl_service:"Servicio",
	date_lbl_date:"Fecha",
	date_lbl_time:"Hora",
	date_lbl_phone:"Teléfono",
	date_lbl_comment:"Comentario",
	date_btn_cancel:"Cancelar",
	
	// General
	
	result_ok:"Operación realizada",
	error_empty_fields:"Datos incorrectos",
	error_operation:"Error en la operación",

	error_params:"100",
	error_query:"101",

	btn_new:"Nuevo",
	btn_add:"Añadir",
	btn_update:"Actualizar",
	btn_delete:"Eliminar",

	// Pending
	pending_info:"Hay documentación que debe ser revisada y firmada antes de iniciar el servico y tener acceso a toda la funcionalidad de la aplicación.<br><br>Por favor, rellene todos los campos de los siguientes formularios y revise y firme los documentos:",
	pending_data:"Documentos pare revisar y firmar",
	pending_agreement_service_form:"Datos para implantación",
	pending_agreement_sepa_form:"Datos para SEPA",
	pending_agreement_service:"Contrato de implantación",
	pending_agreement_service_info:"Términos y condiciones del servicio.",
	pending_agreement_sepa:"Orden de domiciliación SEPA",
	pending_agreement_sepa_info:"Documento para que su banco acepte los recibos emitidos por Farmaval.",
	pending_type_admin:"Administrador",
	pending_type_owner:"Titular",
	pending_lbl_pharm_name:"Nombre de la farmacia",
	pending_lbl_address:"Dirección",
	pending_lbl_city:"Municipio",
	pending_lbl_state:"Comunidad autónoma",
	pending_lbl_nif:"CIF o NIF de empresa",
	pending_lbl_signer_type:"En calidad de:",
	pending_lbl_signer_dni:"DNI",
	pending_lbl_signer_name:"Nombre del firmante",

	pending_lbl_debtor_name:"Nombre del deudor",
	pending_lbl_debtor_city:"Población",
	pending_lbl_debtor_state:"Provincia",
	pending_lbl_debtor_country:"País",
	pending_lbl_debtor_swift:"Swift BIC",
	pending_lbl_debtor_iban:"Número de cuenta-IBAN",

	btn_pending_service:"Revisar y firmar documentos",
	btn_pending_sepa:"Revisar y firmar orden",

	pending_months:"Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre",	

	pending_upload:"Enviar documentación",
	pending_again:"Volver a firmar",

	checking_data:"Documentos en revisión",
	checking_msg:"Se está revisando la documentación.<br><br>Tan pronto como estén confirmados los datos, se les avisará para empezar con la formación del equipo.<br><br>Gracias.",
	
	
	// Profile
	settings_title_info:"Información",	
	settings_title_pharm:"Datos",	
	settings_title_admin:"Titular",	
	settings_title_staff:"Personal",	
	settings_title_drugs:"Productos",	
	settings_title_calendar:"Días de servicio",	
	settings_title_syncro:"Sincronización",	

	// Personal
	staff_lbl_name:"Nombre",
	staff_lbl_title:"Cargo",
	staff_lbl_code:"Nº colegiado",
	staff_lbl_date:"Fecha",
	staff_lbl_comment:"Comentario",
	staff_lbl_active:"Estado",
	staff_lbl_sati:"SATI",

	staff_title_0:"Auxiliar",
	staff_title_1:"Técnico",
	staff_title_2:"Farmaceútico",
	staff_title_3:"Titular",
	staff_title_4:"Otro",
	staff_active_0:"Inactivo",
	staff_active_1:"Activo",

	staff_sati_0:"No",
	staff_sati_1:"Sí",

	staff_confirm_delete:"Teclee <b>eliminar personal</b> y pulse 'Eliminar':",
	staff_confirm_delete_text:"eliminar personal",

	// días de servicio
	day_lbl_name:"Día",
	day_lbl_from:"Desde",
	day_lbl_to:"Hasta",
	day_lbl_comment:"Comentario",
	day_name_0:"Lunes",
	day_name_1:"Martes",
	day_name_2:"Miércoles",
	day_name_3:"Jueves",
	day_name_4:"Viernes",
	day_name_5:"Sábado",
	day_name_6:"Domingo",
	day_confirm_delete:"Teclee <b>eliminar día</b> y pulse 'Eliminar':",
	day_confirm_delete_text:"eliminar día",

	// productos
	prod_lbl_name:"Nombre",
	prod_lbl_info:"Información",
	prod_lbl_price:"Precio",
	prod_lbl_service:"Servicio",
	prod_lbl_creator:"Creador",
	prod_lbl_state:"Estado",
	prod_state_0:"Inactivo",
	prod_state_1:"Activo",
	prod_confirm_delete:"Teclee <b>eliminar producto</b> y pulse 'Eliminar':",
	prod_confirm_delete_text:"eliminar producto",

	// farmacia
	pharm_lbl_name:"Nombre",
	pharm_lbl_business:"Razón social",
	pharm_lbl_nif:"CIF",
	pharm_lbl_address:"Dirección",
	pharm_lbl_city:"Ciudad",
	pharm_lbl_state:"Provincia",
	pharm_lbl_postal:"Código postal",
	pharm_lbl_email:"Email",
	pharm_lbl_phone:"Teléfono fijo",
	pharm_lbl_mobile:"Teléfono móvil",
	pharm_lbl_web:"Web",

	pharm_lbl_admin_name:"Nombre",
	pharm_lbl_admin_code:"Nº colegiado",
	pharm_lbl_admin_email:"Email",
	pharm_lbl_admin_signature:"Firma",
	pharm_lbl_admin_password:"Contraseña",
	
	pharm_lbl_user:"Usuario",
	pharm_lbl_password:"Contraseña",
	pharm_password_empty:"Dejar vacío para no cambiar",
		
	pharm_lbl_language:"Idioma",
	pharm_lbl_password:"Contraseña",
	pharm_lbl_logo:"Logo para informes (aspecto 3/1)",
	pharm_lbl_schedule:"Horario",
	
	pharm_lbl_schedule_0:"Ininterrumpido",
	pharm_lbl_schedule_1:"Partido",
	pharm_lbl_schedule_2:"24 horas",



	day_btn_add:"Añadir horario",
	day_btn_delete:"Eliminar",
	question_remove_day:"Eliminar horario de servicio: ",
	error_loading_days:"Problema al cargar días disponibles.",	
	error_removing_day:"No se ha podido eliminar el horario.",
	error_adding_day:"No se ha podido añadir el horario.",

	// PDF viewer	
	pdf_close:"Cerrar informe",	
	
	// Popup
	popup_ok:"Aceptar",
	popup_close:"Cerrar",
	popup_cancel:"Cancelar",	
	
	// Index
	new_link_more:"Más información",
	
	// Index config products
	products_lbl_list:"Productos disponibles",	
	products_lbl_service:"Servicio",
	error_loading_products:"Problema al cargar los productos.",	
	product_lbl_name:"Producto",
	product_lbl_info:"Información",
	product_lbl_cost:"Precio",
	product_lbl_cn:"CN",	
	
	product_btn_add:"Añadir",
	product_btn_edit:"Modificar",
	product_btn_delete:"Borrar",
	
	product_msg_empty:"Deben rellenarse todos los cmapos.",
	product_msg_cost:"El precio no es un valor correcto.",
	product_msg_error:"Se ha producido un problema al realizar la operación.",
		
	product_question_edit:"¿Modificar el producto seleccioando?",
	product_question_delete:"¿Eliminar el producto seleccionado?",
	
	// Index config days
	
	
	// Index calendar
	calendar_days:"Lu,Ma,Mi,Ju,Vi,Sá,Do",
	calendar_times:"Horarios: ",
	calendar_no_times:"No hay",
	
	calendar_date_title:"Citas del día ",
	calendar_no_dates:"No hay citas",
	btn_cancel_date:"cancelar",
	btn_edit_date:"editar",
	
	lbl_date_phones:"Tels:",
	new_date_text:"Añadir cita el día:",
	new_date_lbl_patient:"Paciente",
	new_date_lbl_staff:"Técnico/a",
	new_date_lbl_service:"Servicio",
	new_date_lbl_date:"Fecha",
	new_date_lbl_time:"Hora",
	new_date_lbl_phone:"Teléfono",
	new_date_lbl_comment:"Comentario",
	new_date_error_empty:"Faltan datos por rellenar",
	new_date_error_server:"Error al añadir la cita",
	new_date_confirm_remove:"Eliminar cita de ",
	error_loading_date:"Error cargando datos",


	// News y Mensajes
	error_loading_news:"No se ha podido cargar las noticias.",	
	lbl_loading_news:"Cargando noticias...",
	title_news:"Noticias",


	// Reports
	
	reports_list:"Servicios",
	reports_staff:"Personal",
	reports_stats:"Informes SATI",
	reports_stats_iretia:"Informes IRETIA",
	
	lbl_report_staff_name:"Nombre",
	lbl_report_staff_SATI:"SATI",
	lbl_report_staff_IRETIA:"IRETIA",
	lbl_report_staff_CEPOC:"CEPOC",
	lbl_report_staff_dates_count:"Citas mostrador",
	lbl_report_staff_dates_service_count:"Citas servicio",
	lbl_report_staff_last_service:"Último servicio",
	lbl_report_staff_last_date:"Última cita",
	lbl_report_staff_active:"Estado",
	lbl_report_staff_state_active:"Activo",
	lbl_report_staff_state_noactive:"Inactivo",
	
	lbl_report_type:"Servicio",
	lbl_report_date:"Fecha",
	lbl_report_name:"Nombre",
	lbl_report_staff:"Técnico",
	lbl_report_sales:"Ventas",
	lbl_report_main:"Inhalador",
	lbl_report_visit:"Visita In.",
	lbl_report_visit_real:"Visita",	
	
	filter_service:"Servicio",
	filter_from:"Desde",
	filter_to:"Hasta",
	filter_patient:"Apellidos paciente",
	filter_btn:"Mostrar",
	filter_type_all:"Todos",
	filter_type_sati:"SATI",
	filter_type_iretia:"IRETIA",
	filter_type_cepoc:"CEPOC",
	filter_type_deleted:"Eliminados",

	report_popup_title:"Editar servicio",
	report_lbl_comment:"Comentario",
	report_lbl_sales:"Ventas",
	report_lbl_product_type:"Producto",
	report_lbl_product_name:"Nombre",
	report_lbl_product_cost:"Coste",
	report_lbl_product_quantiy:"Cantidad",

	report_btn_update:"Actualizar",
	report_btn_recover:"Recuperar",
	report_btn_delete:"Eliminar",
	report_btn_remove:"Eliminar definitivamente",
	report_btn_cancel:"Cancelar",

	report_btn_view_patient:"Informe del paciente",
	report_btn_view_doctor:"Informe derivación médico",

	report_confirm_delete:"Teclee <b>eliminar servicio</b> y pulse 'Eliminar':",
	report_confirm_delete_text:"eliminar servicio",

	report_confirm_remove:"Teclee <b>eliminar definitivamente</b> y pulse 'Eliminar definitivamente':",
	report_confirm_remove_text:"eliminar definitivamente",

	report_sale_remove:"Quitar",
	report_sale_add:"Añadir",

	// Pacientes

	patients_list:"Consulta",
	filter_patient_name:"Apellidos del paciente",
	filter_patient_order:"Ordenar por",
	filter_patient_order_name:"Apellidos",
	filter_patient_order_next_visit:"Próxima visita",
	
	col_patient_name:"Paciente",
	col_patient_com_mode:"Comunicaciones",
	col_patient_contact:"Contacto",
	col_patient_lng:"Idioma",
	col_patient_visits:"Visitas",
	col_patient_next_visit:"Próxima visita",
	patient_contact_email:"Email",
	patient_contact_call:"Llamada",
	patient_contact_sms:"SMS",

	patient_tab_basic:"Datos básicos",
	patient_tab_com:"Comunicaciones",
	patient_tab_clinic:"Médico",
	

	patient_popup_title:"Editar paciente",
	patient_lbl_dni:"DNI",
	patient_lbl_name:"Nombre",
	patient_lbl_surname_1:"Primer apellido",
	patient_lbl_surname_2:"Segundo apellido",
	patient_lbl_date:"Fecha de nacimiento",
	patient_lbl_sex:"Sexo",
	patient_lbl_smoker:"Fumador",
	patient_lbl_town:"Población",
	patient_lbl_postal:"Código postal",
	patient_lbl_com_mode:"Comunicaciones",
	patient_lbl_phone:"Teléfono (llamada)",
	patient_lbl_mobile:"Teléfono (SMS)",
	patient_lbl_email:"Email",
	patient_lbl_language:"Idioma",
	patient_lbl_doctor_genere:"Dr./Dra.",
	patient_lbl_doctor:"Médico de cabecera",
	patient_lbl_center:"Centro médico",
	patient_lbl_comment:"Posibles sustituciones a la derivación por flujo",

	patient_value_yes:"Sí",
	patient_value_no:"No",
	patient_value_man:"Masculino",
	patient_value_woman:"Femenino",
	patient_value_dr:"Doctor",
	patient_value_dra:"Doctora",

	patient_btn_delete:"Eliminar paciente",
	patient_btn_new:"Nuevo paciente",
	patient_btn_add:"Registrar paciente",
	patient_btn_update:"Actualizar datos",
	patient_btn_cancel:"Cancelar",

	patient_confirm_delete:"¡Atención! Utilice esta opción sólo si es un paciente de pruebas. Para confirmar, teclee <b>eliminar paciente</b> y pulse 'Eliminar':",
	patient_confirm_delete_text:"eliminar paciente",

	// Sincronización
	
	syncro_lbl_data:"Datos almacenados localmente",
	syncro_lbl_value:"Total",
	syncro_data_staff:"Personal farmacia",
	syncro_data_drugs:"Medicamentos",
	syncro_data_days:"Días de servicio",
	syncro_data_products:"Productos",
	syncro_data_services:"Servicios",

	syncro_lbl_date:"Última actualiación",
	
	syncro_lbl_services:"Lista de servicios pendientes",
	syncro_data_services_none:"Ninguno",
	btn_syncro_now:"Sincronizar ahora",


	// stats
	btn_stats_print:"Imprimir",
	stats_title_report:"Servicio de Acompañamiento en Terapia Inhalada",
	stats_from:"Informes desde ",
	stats_to:" hasta ",
	stats_financial_title:"Datos financieros económicos",
	stats_visit_all:"Todas las visitas",
	stats_visit_1:"Primera visita",
	stats_visit_2:"Segunda visita",
	stats_visit_3:"Visitas de seguimiento",
	stats_title_services:"Servicios",
	stats_title_time:"Duración",
	stats_title_time_per:"Duración por servicio",
	stats_title_sales:"Ventas",
	stats_title_sales_per:"Ventas por servicio",
	stats_prescription:"Prescripciones",
	stats_title_prescription:"Servicios con al menos una prescripión",
	stats_title_prescription_per:"% de servicios",
	stats_sales:"Productos vendidos",
	stats_title_quantity:"Cantidad",
	stats_title_total:"Total",
	stats_all_staff:"Total",
	stats_farmaval:"Media Farmaval",
	
	chart_services:"SERVICIOS TOTALES",
	chart_time_avg:"TIEMPO MEDIO POR SERVICIO",
	chart_prescriptions:"PRESCRIPCIONES",
	chart_prescriptions_service:"PRESCRIPCIONES POR SERVICIO",
	chart_media:"Farmaval: Media",
	chart_best:"Farmaval: Mejores",
	chart_pharm:"Farmacia",
	chart_visits:"Todas las visitas",
	chart_visit_1:"Primera visita",
	chart_visit_2:"Segunda visita",
	chart_visit_3:"Visitas de seguimiento",
	
	stats_medical_title:"Datos asistenciales",
	stats_technique:"Mejora en la técnica inhalada",
	stats_asma:"Datos de asma",
	stats_asma_kids:"Datos de asma infantil",
	stats_epoc:"Datos de EPOC",
	stats_aco:"Datos de ACO",

	stats_medical_services:"Número de servicios",
	stats_points_asma:"Puntuación media<br><small>5 mal control -> 25 buen control</small>",
	stats_points_asma_kids:"Puntuación media<br><small>0 mal control -> 27 buen control</small>",
	stats_points_epoc:"Puntuación media<br><small>40 impacto muy alto -> 0 impacto bajo</small>",
	stats_points_aco:"Puntuación media<br><small>40 impacto muy alto -> 0 impacto bajo</small>",
	stats_control_perfect:"Control total",
	stats_control_good:"Control bueno",
	stats_control_bad:"No controlado",
	stats_control_ok:"Controlado",
	stats_control_ko:"No controlado",

	stats_impact_low:"Impacto bajo",
	stats_impact_middle:"Impacto medio",
	stats_impact_high:"Impacto alto",
	stats_impact_higher:"Impacto muy alto",

	stats_errors:"Errores más comunes",

	stats_medical_visits:"Visitas",
	stats_medical_visit_1:"Visita 1ª",
	stats_medical_visit_2:"Visita 2ª",
	stats_medical_visit_3:"Visita >2ª",
	stats_medical_improvment:"Mejora",
	stats_medical_quantity:"Cantidad",

	stats_medical_info:"Los cálculos de control de enfermedad se realizan sólo con los datos de la tres primeras visitas. Los resultados de las siguientes visitas de seguimiento no se tienen en cuenta.",

	stats_technique_pharm:"Media de errores en técnica",
	stats_technique_media:"Media Farmaval",

	// stats IRETIA
	stats_pharm:"Farmacia",
	stats_farmaval:"Farmaval",
	stats_iretia_title_report:"Servicio de intervención rápida en terapia inhalada aguda",

	stats_iretia_title_1:"Datos generales",
	stats_iretia_title_2:"Perfil pacientes",
	stats_iretia_title_3:"Diagnósticos",
	stats_iretia_title_4:"Inahaladores más dispensados",
	stats_iretia_title_5:"Errores más comunes",
	stats_iretia_title_6:"Prestación de servicio por miembro del equipo",

	stats_iretia_col_1:"Mi farmacia",
	stats_iretia_col_2:"Media farmacias Farmaval",
	stats_iretia_col_3:"Farmacia más activa",
	
	stats_iretia_1:"Número servicios IRETIA prestados",
	stats_iretia_2:"Tiempo medio invertido por servicio (minutos)",
	stats_iretia_3:"Total ventas asociadas servicio",
	stats_iretia_4:"Media de ventas por servicio",
	stats_iretia_5:"Servicios con ventas asociadas",
	stats_iretia_6:"Media de errores",
	stats_iretia_7:"Servicios con al menos 1 error en la técnica",
	stats_iretia_8:"Valoración muy útil del servicio",
	stats_iretia_9:"Valoración compromiso muy alto de la farmacia",

	stats_iretia_8_1:"Sin diagnóstico",
	stats_iretia_8_2:"Bronquitis aguda",
	stats_iretia_8_3:"Neumonía",
	stats_iretia_8_4:"Covid o Post Covid",
	stats_iretia_8_5:"Lesión de costillas",
	stats_iretia_8_6:"Cardiovascular",
	stats_iretia_8_7:"Otro",

	stats_iretia_9_1:"Mujeres",
	stats_iretia_9_2:"Media edad",
	stats_iretia_9_3:"Fumadores",
	
	stats_iretia_first:"1º",
	stats_iretia_second:"2º",
	stats_iretia_third:"3º",
	
	stats_iretia_team_1:"Servicios",
	stats_iretia_team_2:"Tiempo medio",
	stats_iretia_team_3:"Ventas",
	stats_iretia_team_4:"Media de ventas",
	stats_iretia_team_5:"Servicios con ventas",

	
	stats_iretia_products:"Productos vendidos",
	stats_iretia_product_qty:"Cantidad",
	stats_iretia_product_total:"Total",

	// ******************************************
	// Settings

	settings_info_drugs:"Gestión de productos recomendados para los servicios.",	
	settings_info_calendar:"Selección de días hábiles por defecto para la realización de servicios.",	
	settings_info_syncro:"Información sobre los datos guardados localmente.",	
	settings_info_pharm:"Opciones generales relativas al aplicativo y a la farmacia.",	
	settings_lbl_signature:"Firma del titular",
	settings_lbl_version:"Versión aplicativo",
	settings_lbl_pharm:"Farmacia",
	settings_lbl_disabled:"En desarrollo",
	settings_lbl_noactive:"No activado",
	error_loading_pharm:"Problema al cargar los datos de la farmacia",
	
	no_signature_msg:"El sistema no dispone de una firma del titular de la farmacia, necesaria para firmar los informes.<br><br/>Puede establecerse desde la sección de configuración.", 	
	no_passwords_msg:"Falta la contraseña de acceso. Por favor, establezca la contraseña desde la sección de configuración.", 	
	no_signature_passwords_msg:"El sistema no dispone de una firma del titular de la farmacia, necesaria para firmar los informes, ni tampoco de contraseña de acceso.<br><br/>Pueden establecerse desde la sección de configuración.", 	
	
	settings_btn_new_signature:"Nueva firma",
	signature_pharm_title:"Firma del titular de la farmacia",
	
	settings_password_public_lbl:"Contraseña de acceso",
	settings_password_public_info:"Está asociada al usuario/email de la farmacia y permite acceder al aplicativo y realizar servicios.",
	warning_nopassword:"¡Atención! no hay contraseña.",
	btn_new_password:"Establecer contraseña",
	btn_change_password:"Cambiar contraseña",
	new_password_must_1:"Más de 7 carácteres.",
	new_password_must_2:"Incluir letras.",
	new_password_must_3:"Incluir números.",
	new_password_must_4:"Incluir símbolos.",
	new_password_must_5:"Confirmar contraseña.",
	new_password_value:"Nueva contraseña",
	new_password_repeat:"Confirmar contraseña",
	new_password_public_title:"Cambiar la contraseña de técnicos",
	new_password_error:"Error al intentar asignar la contraseña. Por favor, inténtalo de nuevo.",
	new_password_notallowed:"Un usuario técnico no puede cambiar las contraseñas. Por favor, inicie sesión como administrador.",

	// Service 
	signature_title_modal:"Recogida de datos para la prestación del servicio SATI",
	signature_text_check1:"AUTORIZO el uso de mis datos para la prestación del servicio Acompañamiento en Terapia Inhalada.",
	signature_text_check2:"AUTORIZO el uso de mis datos para la realización de comunicaciones telemáticas (SMS, mensajes, E-mail, etc.)",
	signature_text_check3:"AUTORIZO el uso de mis datos para la realización de estudios de investigación asistencial.",
	signature_text_check4:"AUTORIZO a la farmacia a compartir con el médico que me trata la información sobre el tratamiento inhalado que tomo con el fin de mejorar mis resultados terapéuticos.",

	signature_image_title_modal:"Consentimiento explícito para la publicación y el tratamiento de imágenes general",
	signature_image_radio1:"AUTORIZO el uso de las imágenes",
	signature_image_radio2:"NO AUTORIZO el uso de las imágenes.",
	

	signature_label_patient:"Al ser menor, escribe el nombre de la persona responsable",
	signature_btn_clear:"Empezar de nuevo",
	signature_btn_ok:"Aceptar",
	signature_btn_cancel:"Cancelar",
	signature_error:"No se ha podido almacenar la nueva firma, por favor inténtelo de nuevo.",
	
	service_error_unknown:"El servicio no existe",
	service_error_loading:"Error cargando servicios",
	service_error_patient:"Error cargando formulario de paciente",
	service_error_staff:"Error cargando datos de los técnicos",
	service_error_service:"Error cargando datos del servicio",
	
	service_btn_cancel:"Cancelar servicio",
	service_btn_prev:"Atrás",
	service_btn_next:"Siguiente",
	tab_start_title:"Inicio de servicio",
	tab_start_info:"Seleccione el técnico que realizará el servicio y rellene la ficha del paciente. Si el paciente ya está registrado puede buscarlo por el DNI o sus apellidos.",
	tab_start_info_iretia:"Seleccione el técnico que realizará el servicio y rellene los datos del paciente. En caso de cesión de derechos de imagen, rellene el DNI, nombre y apellidos.",
	tab_start_staff_info:"Técnico del servicio",

	lbl_question_cancel:"¿Seguro que quiere cancelar el servicio? No se guardará ningún dato.",

   lbl_question_new:"Escriba el nombre del nuevo técnico:",
   lbl_question_update:"Escriba el nuevo nombre del técnico:",
   lbl_question_delete:"¿Seguro que quiere eliminar el técnico seleccionado?",
   
	btn_staff_delete:"Eliminar seleccionado",	
	btn_staff_update:"Modificar",	
	btn_staff_new:"Nuevo",	
	
	tab_start_patient_info:"Ficha del paciente",
	lbl_patient_search:"Buscando paciente...",
	lbl_patient_new:"Nuevo paciente",
	lbl_patient_error:"Error en servidor",
	lbl_patient_dni:"DNI",
	lbl_patient_name:"Nombre",
	lbl_patient_surname_1:"Primer apellido",
	lbl_patient_surname_2:"Segundo apellido",
	lbl_patient_date:"Fecha de nacimiento",
	lbl_patient_age:"Edad",
	lbl_patient_sex:"Sexo",
	lbl_patient_smoke:"Fumador",
	
	lbl_patient_extra:"Datos sólo si el paciente cede derechos de imagen",

	options_patient_sex:"Mujer/1,Hombre/0",
	options_patient_smoke:"No/0,Sí/1",
	
	lbl_patient_studies:"Estudios",
	lbl_patient_tsi:"TSI",
	options_patient_studies:"Sin estudios/0,Básicos/1,Bachillerato/2,Grado técnico/3,Universitarios/4",
	
	lbl_patient_town:"Población",
	lbl_patient_postal:"Código postal",
	lbl_patient_phone:"Teléfono fijo (llamada)",
	lbl_patient_mobile:"Móvil (SMS)",
	lbl_patient_contact:"Teléfono Móvil",
	lbl_patient_email:"Email",
	lbl_patient_com_mode:"Comunicaciones",
	lbl_patient_lang:"Idioma",
	options_patient_com_mode:"Por email/0,Por llamada/1,Por SMS/2",
	options_patient_lang:"Castellano/es,Català/ca",
	
	lbl_patient_doctor_genere:"Dr/Dra",
	options_patient_doctor_genere:"Dr./0,Dra./1",
	lbl_patient_doctor:"Médico de cabecera",
	lbl_patient_center:"Centro médico",
	lbl_patient_comment:"Posibles sustituciones a la derivación por flujo",
	
	lbl_patient_sign:"Firma del paciente",
	
	section_patient_title:"Datos básicos del paciente",
	section_patient_info:"Rellene los datos si es nuevo paciente o actualízalos si es necesario",
	
	form_error_staff:"Seleccione tu nombre como técnico del servicio",
	form_error_required:"Faltan datos por rellenar",
	form_error_pattern:"El formato no es el correcto",
	form_error_contact:"Debe rellenar el dato de contacto.",
	form_error_options:"Hay preguntas del cuestionario sin responder.", 
	
	// 0.7.3
	title_chat:"Contacto",
	info_chat:"Puede enviarnos un correo directamente desde aquí.",
	placeholder_chat:"Mensaje a enviar",
	btn_chat_send:"Enviar",
	email_ok:"Gracias, le responderemos lo antes posible.",
	email_error:"No hemos podido enviar el correo, por favor inténtelo de nuevo o póngase en contacto con nosotros a través de farmaval.net",

	// 0.7.5
	rep_title_change:"Servicio a: ",
	rep_comment:"Comentario",
	rep_btn_patient:"Informe paciente",
	rep_btn_doctor:"Informe médico",
	rep_btn_delete:"Eliminar servicio",
	rep_btn_remove:"Eliminar definitivamente",
	rep_btn_confirm:"Confirmar eliminar",
	rep_btn_save:"Actualizar",
	rep_btn_restore:"Restaurar",
	rep_sales:"Productos vendidos",
	rep_products:"Productos",
	rep_prod_name:"Nombre",
	rep_prod_cost:"Precio",
	rep_prod_qty:"Cantidad",
	rep_prod_add:">>",
	rep_prod_other:"Otro",
	rep_result_error:"Error realizando operación",
	rep_result_notallowed:"Sólo el titular puede eliminar registros.",


	service_error_loading_data:"Ha habido un error actualizando los datos para inciar el servicio.",
	service_error_local_data:"No se han podido cargar los datos para iniciar el servicio. Asegúrese que hay conexión a Internet y vuelva a intentarlo.",
	service_warning_local_data:"Los datos para iniciar el servicio puede que estén desactualizados",

	service_online:"Conectado",
	service_offline:"Sin conexión",
	
	end_reports_question:"Confirme que se cierra el servicio.",
	end_error_reports:"Problema al generar informes y almacenar el servicio.",
	end_error_reports_backup:"Problema al guardar el servicio. Se va a almacenar de forma local para no perder los datos.<br><br>Puede realizar la sincronización con el servidor desde la sección de configuración.",


};
