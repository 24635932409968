import { page_text_es } from '../lng/es.js';
import { page_text_ca } from '../lng/ca.js';

window["page_text_es"]=page_text_es;
window["page_text_ca"]=page_text_ca;

const farmaval_version="13.01";
const page_vars=getUrlVars();
export let page_lng="es";
if(localStorage.getItem('farmaval_lng')!==null)
  	page_lng=localStorage["farmaval_lng"];

export const server_base= location.hostname === "localhost" 
	  ? "http://localhost/farma/server/"
	  : "https://www.farmaval.net/server/";
const server_url= server_base+"functions.php";


const old_server_base= location.hostname === "localhost" 
	  ? "http://localhost/farmaval/"
	  : "https://www.farmaval.net/app/";
const old_server_url= old_server_base+"code.php";


export let page_text;

function getUrlVars() 
{
	var vars = {};
	var parts = window.location.href.replace(/[?&#]+([^=&#]+)=([^&#]*)/gi, function(m,key,value) 
	{
		vars[key] = value;
	});
	return(vars);
}

export function translate_page(lng, update=true)
{
	if(update)
	{
		localStorage["farmaval_lng"]=lng;
		page_lng=lng;
	}

	page_text=window["page_text_"+lng];

    document.querySelectorAll("[aria-lng]").forEach(el=>{
        let text=el.getAttribute("aria-lng");
		if(page_text[text])
			el.innerHTML=page_text[text];
    });
    
	document.querySelectorAll("[aria-holder]").forEach(el=>{
        let text=el.getAttribute("aria-holder");
		if(page_text[text])
			el.setAttribute("placeholder",page_text[text]);
    });

	document.querySelectorAll("[aria-href]").forEach(el=>{
        let text=el.getAttribute("aria-href");
		if(page_text[text])
			el.setAttribute("href",page_text[text]);
    });
    return;

	
	
	var elements=$(root).find("[aria-select]");
	for(i=0;i<elements.length;i++)
	{
		var select=$(elements[i]).attr("aria-select");
		if(page_text[select])
		{
			$(elements[i]).html("");
			var text=page_text[select];
			var options=text.split(",");
			for(o=0;o<options.length;o++)
			{
				var op=options[o].split("/");
				$(elements[i]).append('<option value="'+op[1]+'">'+op[0]+'</option');
			}
			$(elements[i]).val("");
		}
	}	
	
	
	
	return;
}

export function set_version()
{
	document.querySelectorAll(".farmaval_version").forEach(el=>{
			el.innerHTML=farmaval_version;
    });
}

export function set_enter()
{
	document.querySelectorAll("[aria-enter]").forEach(el=>{
        el.addEventListener("keyup",e=>{
			if (e.keyCode === 13)
			{
				e.preventDefault();
				document.getElementById(el.getAttribute("aria-enter")).click();
			}
		})
    });
}

export async function server_function(func,params,ret=null,session=true)
{
	
	let json;
	try
	{
		if(func)
			params.function=func;
		if((session) && (localStorage.farmaval_session))
			params.session=localStorage.farmaval_session;

		const res = await fetch(server_url, 
			{
				method: 'post',
				mode: "cors",
				headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(params)
			});	
		json=await res.json();
		if(!json) throw new Error("server_error");
	}catch(e){
		if(!ret) return(false);
		ret(false);
		return;
	}
	if(!ret) return(json);
	ret(json);
	return;
}

export async function old_server_function(params,session=true)
{
	let json;
	try
	{
		if((session) && (localStorage.farmaval_session))
			params.session=localStorage.farmaval_session;

		const res = await fetch(old_server_url, 
			{
				method: 'post',
				mode: "cors",
				headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams(params)
			});	
		json=await res.json();
		if(!json) throw new Error("server_error");
		return(json);
	}catch(e){
		return({result:"ko",type:"server_error"});
	}
}